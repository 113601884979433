<template>
  <div>
    <router-view />
  </div>
</template>
<script>

export default {
}
</script>

<style>
html, body {
  background: #f4f3ef;
  margin: 0 !important;
  padding: 0 !important;
  min-height: 100vh;
}
.hero.is-fullheight {
  min-height: 100vh;
  margin: 0 ;
}
</style>
